import {
    CHANGE_LANG
  } from "../constants/actionTypes";
  const initialState = {
    locale: localStorage.getItem("userdata") && localStorage.getItem("language")
    ?  JSON.parse(localStorage.getItem("language"))[JSON.parse(localStorage.getItem("userdata")).id]
    : "EN",
  }

  export default function(state = initialState, action) {
    switch(action.type){
      case CHANGE_LANG:
      return {
       locale: action.payload
      };
      default:
        return state;
    }
  }