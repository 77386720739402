const CustomLoader = () => {
  return (
    <>
      <div className="loader">
        <img src="/assets/images/loader.gif" width="40px" />
      </div>
    </>
  );
};
export default CustomLoader;
