import React, { useEffect, useState } from "react";
import { ChangeLanguage } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";

function Languages(props) {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.langReducer).locale;
  const [lang, setLang] = useState(locale);
  const changeLang = () => {
    dispatch(ChangeLanguage({lang:lang == "EN" ? "NL" : "EN",id:props.userData ? props.userData.id:null}));
    setLang(lang == "EN" ? "NL" : "EN");
    
  };
  const urlParams = new URLSearchParams(window.location.search);
const defaultLanguage = urlParams.get('lang') || 'en';
useEffect(()=>{
  if(urlParams.get('lang') && (urlParams.get('lang').toLowerCase() !== lang.toLowerCase())){
    dispatch(ChangeLanguage({lang:urlParams.get('lang').toUpperCase(), id:props.userData ? props.userData.id:null}));
    setLang(urlParams.get('lang').toUpperCase());
  }
},[])
  return (
    <ul className="login-language-menu">
      <li className={`language-switcher ${lang == 'EN' ? 'lang-active':''}`} onClick={() => changeLang()}>
        <a href="#!" onClick={(e) => e.preventDefault()}>
          <span>EN</span>
        </a>
      </li>
      <li className={`language-switcher ${lang == 'NL' ? 'lang-active':''}`} onClick={() => changeLang()}>
        <a href="#!" onClick={(e) => e.preventDefault()}>
          <span>NL</span>
        </a>
      </li>
    </ul>
  );
}

export default Languages;
