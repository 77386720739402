import { SET_ROWS_PER_PAGE } from "../constants/actionTypes";
  
  const initialState = {
    rowsPerPage: localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : 8,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case SET_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.payload,
        };
      default:
        return state;
    }
  }
  