module.exports = {
  USER_LOADED: "USER_LOADED",
  AUTH_ERROR: "AUTH_ERROR",
  SUCCESS_MESSAGE: "SUCCESS_MESSAGE",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  WARNING_MESSAGE: "WARNING_MESSAGE",
  INFO_MESSAGE: "INFO_MESSAGE",
  CHANGE_LANG: "CHANGE_LANG",
  SET_STYLE: "SET_STYLE",
  SET_STYLE_PENDING: "SET_STYLE_PENDING",
  SET_CUSTOMERS: "SET_CUSTOMERS",
  SET_USERS: "SET_USERS",
  SET_SEARCH_USERS: "SET_SEARCH_USERS",
  SET_QUESTIONNAIRE: "SET_QUESTIONNAIRE",
  GET_CUSTOMERPERMISSIONS: "GET_CUSTOMERPERMISSIONS",
  SET_CUSTOMER_PERMISSION: "SET_CUSTOMER_PERMISSION",
  GET_USERSLIST: "GET_USERSLIST",
  VIEW_CUSTOMER: "VIEW_CUSTOMER",
  SET_PENDING: "SET_PENDING",
  SET_CUSTOMER_USERS: "SET_CUSTOMER_USERS",
  VIEW_USER: "VIEW_USER",
  SET_PENDING_USERS: "SET_PENDING_USERS",
  CHECK_SHORT_NAME: "CHECK_SHORT_NAME",
  USER_LOGOUT: "USER_LOGOUT",
  SET_SEARCH_CUSTOMER: "SET_SEARCH_CUSTOMER",
  SET_FONT_FAMILY_LIST: "SET_FONT_FAMILY_LIST",
  REMOVE_STYLE_PENDING: "REMOVE_STYLE_PENDING",
  SET_QUESTIONNAIRE_CAT: "SET_QUESTIONNAIRE_CAT",
  SET_CUSTOMER_STYLE: "SET_CUSTOMER_STYLE",
  VIEW_QUESTIONNAIRE: "VIEW_QUESTIONNAIRE",
  SET_QUESTIONNAIRE_PENDING: "SET_QUESTIONNAIRE_PENDING",
  SET_PDF_DATA: "SET_PDF_DATA",
  SET_PDF_PENDING: "SET_PDF_PENDING",
  GET_PDF_DATA: "GET_PDF_DATA",
  SET_USER_CUSTOMERS: "SET_USER_CUSTOMERS",
  SET_USER_SELECTED_CUSTOMER: "SET_USER_SELECTED_CUSTOMER",
  SET_ANSWER_LIST: "SET_ANSWER_LIST",
  SET_ANSWER_LIST_PENDING: "SET_ANSWER_LIST_PENDING",
  SET_ANSWER_DATA: "SET_ANSWER_DATA",
  REMOVE_ANSWER_DATA: "REMOVE_ANSWER_DATA",
  SET_PDF_URL: "SET_PDF_URL",
  SET_CUST_PERM_PENDING: "SET_CUST_PERM_PENDING",
  SET_DOC_DATA: "SET_DOC_DATA",
  SET_DOC_PENDING: "SET_DOC_PENDING",
  SET_SINGLE_USER_PERM: "SET_SINGLE_USER_PERM",
  SET_EMAIL_TEMPLATE: "SET_EMAIL_TEMPLATE",
  LOGGED_USER_INFO: "LOGGED_USER_INFO",
  SET_REGISTRATION_TEMPLATE:"SET_REGISTRATION_TEMPLATE",
  SET_ROWS_PER_PAGE:"SET_ROWS_PER_PAGE"
};
