import * as actionTypes from "../constants/actionTypes";

export const checkEmptyObjectOrarray = (formdata) => {
  if (Array.isArray(formdata)) {
    if (formdata.length > 0) {
      return true;
    }
  } else if (formdata !== null) {
    {
      return true;
    }
  } else {
    return false;
  }
};

export const setRowsPerPage = (data) => (dispatch) => {
  localStorage.setItem('rowsPerPage',data);
  dispatch({
    type: actionTypes.SET_ROWS_PER_PAGE,
    payload: data
  })
}
