import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStyle } from "../../actions/customerAction";
import HeaderContainer from "../Header/HeaderContainer";
import Languages from "../Header/Languages";
import UserMenu from "../Header/UserMenu";
import CustomerStyle from "./CustomerStyle";

function CustomerStyleContainer(props) {
  const locale = props.locale;
  let selectedStyle = useSelector((state) => state.styleReducer).style;
  const customerData = localStorage.getItem("customerdata") ? JSON.parse(localStorage.getItem("customerdata")):null;
  const selectedCustomer = localStorage.getItem("selCustomer") ? JSON.parse(localStorage.getItem("selCustomer")):null;
  const [addNewStyle,setNewStyle] = useState([]);
  const [customerAllStyle,setCustomerAllStyle] = useState([]);
  const dispatch = useDispatch();

 useEffect(()=>{
  if(customerData){
    setCustomerAllStyle(customerData.allStyles);
  }
},[])
  const addMoreStyle = () => {
    let newStyleNumber = [...addNewStyle];
    newStyleNumber.push(1);
    setNewStyle([...newStyleNumber]);
  }

  const activateStyle = (style_number,id) => {
    dispatch(GetStyle({ customer_id: id, style_number: style_number}));
  }
  return (
    <>
      <div className="body-content">
        {customerAllStyle.map((style,i)=>(
        <>
        <div key={i}>
          <div className="flex justify-between items-center mb-2">
          <h2>Style {i+1}</h2>
          <button className={`btn btn-primary ${selectedStyle.iqbstyle.style_number == style.iqbstyle.style_number ? 'btn-active':""}`} onClick={()=>activateStyle(style.iqbstyle.style_number,selectedCustomer?selectedCustomer.data.id:props.auth.userdata.id)}>{selectedStyle.iqbstyle.style_number == style.iqbstyle.style_number ? 'Active':"Inactive"}</button>
          </div>
        <CustomerStyle style_number={style.iqbstyle.style_number} style={style} {...props} />
        </div>
        <hr className="mt-4 mb-4"/>
        </>
        )
        )}
        {addNewStyle.length ? addNewStyle.map((item,i)=>(
     <>
     <div key={i+"customer"} className="mt-4">
     <div className="flex justify-between items-center mb-2">
     <h2>Style {customerAllStyle.length+i+1}</h2>
     </div>
     <CustomerStyle style_number={customerAllStyle.length+i+1} style={customerAllStyle[0]} {...props} />  
     </div>
     <hr className="mt-4 mb-4"/>
     </>
        )):""}
      {selectedCustomer && selectedCustomer.data.id == props.auth.userdata.id ? <button className="btn btn-primary" onClick={()=>addMoreStyle()}>Add More Style</button>: !selectedCustomer ? <button className="btn btn-primary" onClick={()=>addMoreStyle()}>Add More Style</button>:"" }
      </div>
    </>
  );
}

export default CustomerStyleContainer;
