export const EN = {
  Lang: "EN",
  Upload_logo_here: "Upload logo here",
  Setting: "Settings",
  Log_out: "Log out",
  Customer: "Customer",
  User: "User",
  Super_admin:"Super Admin",
  Management: "Management",
  Permissions: "Permissions",
  Style: "Style",
  Invoicing: "Invoicing",
  Hello: "Hello",
  Welcome_note: "Welcome to the IQB",
  Customer_welcome_content:
    "In this tool you can complete the questions regarding the load ability and/or the limitations of an insured employee online for both version 4 and version 5 You can then generate the report and use it in your final report",
  Admin_welcome_content:
    "In this tool you can complete the questions regarding the load ability and/or the limitations of an insured employee online for both version 4 and version 5 You can then generate the report and use it in your final report",
  User_welcome_content:
    "In this tool you can complete the questions regarding the load ability and/or the limitations of an insured employee online for both version 4 and version 5 <br /> You can then generate the report and use it in your final report",
  Note: "Note: No data is stored in the process of answering the questions and preparing the report as a PDF document",
  User_Management: "User Management",
  Search_user_placeholder: "Search Users by Name/Email....",
  Search_customer_placeholder: "Search customers...",
  Add_Existing_User: "Restore User",
  Not_existing_user: "There are not any existing users!",
  Add_user: "Add user",
  Remove: "Remove",
  Add: "Add",
  Email: "Email",
  Status: "Status",
  Start_date: "Start date",
  End_date: "End date",
  Customers: "Customers",
  Actions: "Actions",
  Edit: "Edit",
  View: "View",
  Delete: "Delete",
  Active: "Active",
  Inactive: "Inactive",
  First_name: "First name",
  Last_name: "Last name",
  Search: "Search",
  Submit: "Submit",
  customer_name: "Customer name",
  Searchable: "Searchable",
  Date_time: "Date time",
  Phone_No: "Phone no.",
  Email_address: "Email address",
  Invalid_email_address: "Invalid email address",
  Please_enter: "Please enter",
  valid_first_name: "valid first name",
  valid_last_name: "valid last name",
  valid_phone_number: "valid phone number",
  Name: "Name",
  Questionnaire: "Questionnaire",
  Font_family: "Font family",
  Font_size: "Font size",
  Primary_color: "Primary color",
  Secondary_color: "Secondary color",
  Support_format: "Support format png, jpg and size should be max. 300kb",
  Save: "Save",
  Title_color: "Title color",
  Header_color: "Header color",
  Background_color: "Background color",
  Home: "Home",
  Admin: "Admin",
  User_access_management: "User Access Management",
  Search_customer: "Search customers...",
  Company: "Company",
  Fml_file_no: "FML unique Nr.",
  Quick_links: "Quick Links",
  Top: "Top",
  End: "End",
  Basic_info: "General Info",
  No_of_user: "Nr of users",
  Business_Name: "Business name",
  Search_customer_by_name_email: "Search Customers by Name/Email....",
  Add_new_customer: "Add New Customer",
  Add_new_questionnaire: "Add New Questionnaire",
  Add_Existing_Customer: "Restore Customer",
  Add_new_question: "Add New Question",
  Type: "Type",
  Expire: "Expire",
  Mobile: "Mobile",
  Short_Name: "Short name",
  Address: "Address",
  Zip_Code: "Zip code",
  Residance: "Residance",
  Country: "Country",
  Billing_Email: "Billing email",
  Phone: "Phone",
  Discount: "Discount",
  Please_enter_valid_short_name: "Please enter valid shortname",
  Short_name_is_available: "Short name is available",
  Short_name_has_already_been_used: "Short name has been already used.",
  Toggle: "Toggle",
  Questionnaire_Access_Management: "Questionnaire Access Management",
  Select_user: "Select user",
  Questions: "Questions",
  Questionnaire_name_goes_here: "Questionnaire name goes here...",
  Category: "Category",
  Download_pdf: "Download PDF",
  Download_doc: "Download DOC",
  Change_password: "Change password",
  Current_password: "current password",
  New_password: "new password",
  Confirm_new_password: "confirm new password",
  Enter: "Enter",
  Enter_your_name: "Enter your name",
  Enter_your_lastname: "Enter your last name",
  Enter_your_number: "Enter your number",
  Enter_your_email: "Enter your email",
  Enter_your_password: "Enter your password",
  Password_strength_text:
    "The password should be min 8 and max 20 characters. It should have atleast a smallcase, uppercase, number and special character without white spaces",
  Password_does_not_match: "Password does not match",
  First_name_is_required: "First name is required",
  Last_name_is_required: "Last name is required",
  Phone_no_is_required: "Phone no. is required",
  Email_is_required: "Email is required",
  Email_invalid: "Email invalid",
  Start_date_is_required: "Start date is required",
  Searchable_is_required: "Searchable is required",
  Customer_name_is_required: "Customer name is required",
  Please_enter_a_valid_kvk: "Please enter a valid kvk",
  Select_a_type: "Select a type",
  Business_name_is_required: "Business name is required",
  Address_is_required: "Address is required",
  Residence_address_is_required: "Residence address is required",
  Please_enter_a_valid_country_name: "Please enter a valid country name",
  End_date_is_required: "End date is required",
  Signed_out_successfully: "Signed out successfully",
  User_settings_update_successfully: "User settings update successfully",
  Password_changed_successfully: "Password updated successfully",
  Record_recovered_successfully: "Record recovered successfully",
  Record_deleted_successfully: "Record deleted successfully",
  Deleted_successfully: "Deleted successfully",
  Something_went_wrong:"Something went wrong",
  Record_updated_successfully: "Record updated successfully",
  User_add_successfully: "User added successfully",
  User_updated_succeessfully: "User data updated successfully",
  Customer_updated_successfully: "Customer updated successfully",
  Customer_added_successfully: "Customer added successfully",
  Data_updated_successfully: "Data updated successfully",
  Data_submitted_successfully: "Data submitted successfully",
  Customer_permission_updated_successfully:
    "Customer permission updated successfully",
  User_permission_updated_successfully: "User permission updated successfully",
  Customer_style_updated: "Customer IQB style updated",
  Updated_successfully: "Updated successfully",
  Subject: "Subject",
  Customer_pdf_style_updated: "Customer PDF style updated",
  File_saved_succeessfully: "File saved succeessfully",
  PDF_file_downloaded_successfully: "PDF file downloaded successfully",
  DOC_file_downloaded_successfully: "DOC file downloaded successfully",
  Are_you_sure_you_want_to_delete: "Delete! Are you sure?",
  Select_at_least_one_user: "Select at least one user.",
  Email_already_exist: "Email already exist",
  Please_fill_the_form_first: "Please fill the form first",
  File_no_is_required: "File no is required",
  Please_select_the_date: "Please select the date",
  Selected_successfully: " Selected successfully",
  Is_required: "is required!",
  To_FML: "To FML",
  To_KFML: "To KFML",
  Message_Body: "Message Body",
  Update: "Update",
  Email_template: "Reset mail",
  Message_body_required: "Message body is required",
  Subject_required: "Subject is required",
  Login: "Login",
  LoginPara: "Use your email to continue with IQB",
  Password: "Password",
  Remember_me: "Remember me?",
  Forgot_password: "Forgot password?",
  Not_access_yet: "Not access yet?",
  All_right_reserved: "All right reserved.",
  Built_with: "Built with",
  Achieving_more_with_less: "Achieving more with less",
  Quick_and_easy_form_obligation: "Quick and easy form obligation",
  Reset_Password: "Reset Password",
  Reset_Password_Para: "Enter the email address and we'll send you a link to reset password.",
  Send: "Send",
  Back_to: "Back to",
  Please_enter_email_address: "Please enter email address",
  Please_enter_password: "Please enter password",
  Reset_link_sent_successfully: "Reset link sent successfully!",
  Reset_content:
    "Password reset link sent succeessfully to your email address please click on the link to reset password!",
  Go_to: "Go to",
  Confirm_password: "Confirm password",
  Enter_confirm_password: "Enter confirm password",
  Enter_email_addr_content:
    "Enter the email address and we'll send you a link to reset password.",
  Navigations: "Menu",
  Select_the_retention_period:"Select the retention period",
  Select_date:"Select Date",
  Customize_the_questionnaire: "Customize the questionnaire",
  Is_this_questionnaire_type_FML: "Is this questionnaire type FML?",
  Do_Uncategorized_section_visible_on_frontend:
    "Do Uncategorized section visible on frontend?",
  Enter_FML_title: "Enter FML title",
  Sign_in_paragraph: "Use your email to continue with IQB",
  Customizations: "Customizations",
  Request_access: "Request Access",
  Request_access_para: "Sign up for trial period",
  Register_customer_message:
    "You are registered. Check your email for the login details!",
  Register: "Register",
  Trial_expired: "Trial account has expired!",
  Subs_expired: "Subscription expired!",
  Sidenav_subscription_left: "Your subscription will expire in",
  Sidenav_trial_left: "left for your trial period!",
  Trial_will_expire: "Trial period will expire in",
  Subscription_will_expire: "Subscription will expire in",
  Sign_up: "Sign up",
  Sign_in: "Login",
  Have_an_account: "Have an account?",
  Sign_up_for_trial_period: "Sign up for trial period",
  Registration_mail:"Registration mail",
  Home: "Home",
  About_us: "About us",
  Forms: "Forms",
  Contact_us: "Contact us",
  Terms_and_conditions: "Terms and conditions",
  Privacy_policy: "Privacy policy",
  Cookie_policy: "Cookie policy",
  Lets_connect: "Let's connect"
};
